<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ข้อมูลป๊อปอัพหน้าเว็บไซต์
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3" v-if="$isLoading">
        <b-row class="pl-1">
          <b-col cols="6">
            <div class="panel d-flex align-items-md-center">
              <b-form-checkbox
                size="lg"
                id="sameLang"
                class="mt-0 mt-sm-3"
                @change="useSameLanguage"
                v-model="form.isSameLanguage"
                >ใช้เหมือนกันทุกภาษา
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="6" class="text-right">
            <div class="panel">
              <b-button
                type="button"
                class="btn btn-language"
                v-for="(language, index) in languageList"
                v-bind:key="index"
                v-bind:class="[languageActive == language.id ? 'active' : '']"
                @click="changeLanguage(language.id, index)"
                :disabled="form.isSameLanguage ? true : false"
              >
                <span class="text-uppercase">{{ language.nation }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col md="6">
            <div
              v-for="(item, index) in form.bannerTranslationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <InputText
                  textFloat="ชื่อป๊อปอัพหน้าเว็บไซต์"
                  placeholder="ชื่อป๊อปอัพหน้าเว็บไซต์"
                  type="text"
                  name="name"
                  v-model="form.banner.name"
                  @onKeyup="setAlttagByLang(form.banner.name, index)"
                  isRequired
                  :isValidate="$v.form.banner.name.$error"
                  :v="$v.form.banner.name"
                />
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div
              v-for="(item, index) in form.bannerTranslationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <InputText
                  textFloat="Alt tag"
                  placeholder="Alt tag"
                  type="text"
                  name="name"
                  :img="imageLogoLang"
                  v-model="item.altTag"
                  @onKeyup="setAlttagByLang(item.altTag, index)"
                  isRequired
                  :isValidate="
                    $v.form.bannerTranslationList.$each.$iter[index].altTag
                      .$error
                  "
                  :v="$v.form.bannerTranslationList.$each.$iter[index].altTag"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <DateTimePicker
              textFloat="วันเริ่มต้น"
              type="datetime"
              isTime
              isRequired
              placeholder="กรุณาเลือกวันที่และเวลา"
              v-model="form.banner.startDate"
              :isValidate="$v.form.banner.startDate.$error"
              :v="$v.form.banner.startDate"
            />
          </b-col>
          <b-col md="6">
            <DateTimePicker
              textFloat="วันสิ้นสุด"
              type="datetime"
              isTime
              isRequired
              placeholder="กรุณาเลือกวันที่และเวลา"
              v-model="form.banner.endDate"
              :isValidate="$v.form.banner.endDate.$error"
              :v="$v.form.banner.endDate"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <InputText
              textFloat="ลำดับการแสดง"
              placeholder="ลำดับการแสดง"
              type="text"
              name="sortorder"
              @onKeypress="isNumber($event)"
              v-model="form.banner.sortOrder"
            />
          </b-col>
          <b-col md="6">
            <InputText
              textFloat="ลิ้งค์ไปยังภายนอก"
              placeholder="ลิ้งค์ไปยังภายนอก"
              type="text"
              name="linkurl"
              v-model="form.banner.linkUrl"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <div
              v-for="(item, index) in form.bannerTranslationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <UploadFile
                  textFloat="ภาพป๊อปอัพหน้าเว็บไซต์สำหรับคอมพิวเตอร์"
                  placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                  format="all"
                  name="thumbnail"
                  :fileName="item.fileNameComputer"
                  text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 1:1 ขนาดไม่เกิน 50 MB"
                  isRequired
                  @onFileChange="(val) => onImageChange(val, item, index)"
                  @delete="(val) => deleteImage(val, item, index)"
                  :img="imageLogoLang"
                  :isValidate="
                    $v.form.bannerTranslationList.$each.$iter[index].imageUrl
                      .$error
                  "
                  :v="$v.form.bannerTranslationList.$each.$iter[index].imageUrl"
                />
                <div
                  class="preview-box ratio-1-1-pb"
                  v-if="item.coverImgType == 1"
                  v-bind:style="{
                    'background-image': 'url(' + item.showPreview + ')',
                  }"
                >
                  <img
                    src="/img/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="item.isLoadingImage"
                  />
                </div>
                <!-- <div
                  class="preview-box position-relative p-0 embed-responsive embed-ratio-16-9-pb"
                  v-else
                >
                  <video ref="videoRef" class="w-100 video-box" controls>
                    <source :src="showPreview" type="video/mp4" />
                  </video>
                </div> -->
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div
              v-for="(item, index) in form.bannerTranslationList"
              v-bind:key="index"
            >
              <div
                v-bind:class="[
                  languageActive == item.languageId ? '' : 'd-none',
                ]"
              >
                <UploadFile
                  textFloat="ภาพป๊อปอัพหน้าเว็บไซต์สำหรับมือถือ"
                  placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                  format="all"
                  name="thumbnail"
                  :fileName="item.fileNameMobile"
                  text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 1:1 ขนาดไม่เกิน 50 MB"
                  isRequired
                  @onFileChange="(val) => onMobileImageChange(val, item, index)"
                  @delete="(val) => deleteMobileImage(val, item, index)"
                  :img="imageLogoLang"
                  :isValidate="
                    $v.form.bannerTranslationList.$each.$iter[index]
                      .mobileImageUrl.$error
                  "
                  :v="
                    $v.form.bannerTranslationList.$each.$iter[index]
                      .mobileImageUrl
                  "
                />
                <div
                  class="preview-box ratio-1-1-pb"
                  v-if="item.mobileCoverImgType == 1"
                  v-bind:style="{
                    'background-image': 'url(' + item.mobileShowPreview + ')',
                  }"
                >
                  <img
                    src="/img/loading.svg"
                    class="loading"
                    alt="loading"
                    v-if="item.isLoadingMobileImage"
                  />
                </div>
                <!-- <div
                  class="preview-box position-relative p-0 embed-responsive embed-ratio-10-4-pb"
                  v-else
                >
                  <video ref="videoMobileRef" class="w-100 video-box" controls>
                    <source :src="mobileShowPreview" type="video/mp4" />
                  </video>
                </div> -->
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <label class="font-weight-bold main-label">
              สถานะการแสดงผล
              <span class="text-danger">*</span>
            </label>
            <div>
              <b-form-checkbox
                switch
                v-model="form.banner.display"
                class="radio-active"
                size="lg"
              >
                <span class="ml-2 main-label">{{
                  form.banner.display ? "ใช้งาน" : "ไม่ใช้งาน"
                }}</span>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col md="6">
            <b-button
              v-if="isEdit"
              class="btn btn-danger btn-details-set mr-md-2"
              :disabled="isDisable"
              @click="openModalDelete(form.banner.name)"
              >ลบ</b-button
            >
            <router-link to="/banner-popup">
              <b-button :disabled="isDisable" class="btn-details-set btn-cancel"
                >ย้อนกลับ</b-button
              >
            </router-link>
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="checkForm(0)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
            <button
              type="button"
              @click="checkForm(1)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึกและออก
            </button>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="isModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import UploadFile from "@/components/inputs/UploadFile";
import { required } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import Vue from "vue";

const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) > new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) > new Date(value);
};

export default {
  name: "BannerPopupDetails",
  components: {
    InputText,
    DateTimePicker,
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoadingImage: false,
      isLoadingMobileImage: false,
      coverImgType: 1,
      showVideo: "",
      showPreview: "",
      mobileCoverImgType: 1,
      mobileShowVideo: "",
      mobileShowPreview: "",
      isEdit: false,
      modalMessage: "",
      isDisable: false,
      fileNameComputer: "",
      fileNameMobile: "",
      languageList: [],
      languageActive: 1,
      imageLogoLang: "",
      form: {
        banner: {
          id: 0,
          name: null,
          linkUrl: null,
          display: false,
          sortOrder: 0,
          mainLanguageId: 1,
          startDate: "",
          endDate: "",
        },
        imageBase64: null,
        mobileImageBase64: null,
        isSameLanguage: true,
        bannerTranslationList: [
          {
            bannerId: 0,
            languageId: 1,
            altTag: null,
            isVideo: false,
            isMobileVideo: false,
            imageUrl: "",
            fileNameComputer: "",
            showPreview: "",
            coverImgType: 1,
            isLoadingImage: false,
            mobileImageUrl: "",
            fileNameMobile: "",
            mobileShowPreview: "",
            isLoadingMobileImage: false,
            mobileCoverImgType: 1,
          },
          {
            bannerId: 0,
            languageId: 2,
            altTag: null,
            isVideo: false,
            isMobileVideo: false,
            imageUrl: "",
            fileNameComputer: "",
            showPreview: "",
            coverImgType: 1,
            isLoadingImage: false,
            mobileImageUrl: "",
            fileNameMobile: "",
            mobileShowPreview: "",
            isLoadingMobileImage: false,
            mobileCoverImgType: 1,
          },
        ],
      },
      imgTypeId: 7,
    };
  },
  validations() {
    return {
      form: {
        banner: {
          name: { required },
          startDate: {
            required,
            maxDateTime(val, { endDate }) {
              return maxDate(val, endDate);
            },
          },
          endDate: {
            required,
            minDateTime(val, { startDate }) {
              return minDate(val, startDate);
            },
          },
        },
        bannerTranslationList: {
          $each: {
            altTag: { required },
            imageUrl: { required },
            mobileImageUrl: { required },
          },
        },
      },
    };
  },
  created: async function () {
    await this.getDatas();
    await this.changeLanguage(1, 0);
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeSameLang: function (value) {
      this.form.isSameLanguage = value;
    },
    setAlttag: function (value) {
      this.languageList.forEach((element, index) => {
        this.form.bannerTranslationList[index].altTag = value.replace(
          / /g,
          "-"
        );
      });
    },
    setAlttagByLang: function (value, index) {
      this.form.bannerTranslationList[index].altTag = value.replace(/ /g, "-");
    },
    getDatas: async function () {
      this.$isLoading = false;
      let form = null;

      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
      }
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/BannerPopup/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        form = data.detail;
        this.$v.form.$reset();

        if (form.banner.id > 0) {
          this.isEdit = true;
          form.banner.startDate = `${
            data.detail.banner.startDate.split(" ")[0]
          }T${data.detail.banner.startDate.split(" ")[1]}.000+07:00`;
          form.banner.endDate = `${data.detail.banner.endDate.split(" ")[0]}T${
            data.detail.banner.endDate.split(" ")[1]
          }.000+07:00`;
          for (
            let index = 0;
            index < form.bannerTranslationList.length;
            index++
          ) {
            let banner = form.bannerTranslationList[index];
            form.bannerTranslationList[index].showPreview = banner.imageUrl;
            form.bannerTranslationList[index].fileNameComputer =
              banner.imageUrl;
            form.bannerTranslationList[index].coverImgType = 1;

            form.bannerTranslationList[index].mobileShowPreview =
              banner.mobileImageUrl;
            form.bannerTranslationList[index].fileNameMobile =
              banner.mobileImageUrl;
            form.bannerTranslationList[index].mobileCoverImgType = 1;

            // let vdo = banner.imageUrl.split(".").pop();
            // if (vdo == "mp4") {
            //   form.bannerTranslationList[index].coverImgType = 2;
            //   var vid = this.$refs.videoRef;
            //   if (vid != undefined) {
            //     vid.load();
            //   }
            // }
            // let vdoMobile = banner.imageUrl.split(".").pop();
            // if (vdoMobile == "mp4") {
            //   form.bannerTranslationList[index].mobileCoverImgType = 2;
            //   var vid = this.$refs.videoRef;
            //   if (vid != undefined) {
            //     vid.load();
            //   }
            // }
          }
        } else {
          for (
            let index = 0;
            index < form.bannerTranslationList.length;
            index++
          ) {
            form.bannerTranslationList[index] = {
              ...form.bannerTranslationList[index],
              bannerId: 0,
              isVideo: false,
              isMobileVideo: false,
              fileNameComputer: "",
              showPreview: "",
              coverImgType: 1,
              isLoadingImage: false,
              fileNameMobile: "",
              mobileShowPreview: "",
              isLoadingMobileImage: false,
              mobileCoverImgType: 1,
            };
          }
        }

        this.$isLoading = true;
      }
      this.form = form;

      if (this.form.isSameLanguage) {
        this.languageActive = this.form.banner.mainLanguageId;
      } else {
        var index = this.languageList
          .map(function (x) {
            return x.id;
          })
          .indexOf(this.form.banner.mainLanguageId);
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    changeLanguage(id, index) {
      if (!this.form.isSameLanguage) {
        this.languageActive = id;
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    onImageChange(img, item, index) {
      this.form.bannerTranslationList[index].isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.images = reader.result;
        let images = await this.saveImagetoDb(reader.result);
        this.form.bannerTranslationList[index].isLoadingImage = false;
        this.isDisable = false;

        this.form.bannerTranslationList[index].showPreview = images;
        this.form.bannerTranslationList[index].imageUrl = images;
        this.form.bannerTranslationList[index].fileNameComputer = images;

        if (img.type == "video/mp4") {
          this.form.bannerTranslationList[index].coverImgType = 2;
          this.form.bannerTranslationList[index].isVideo = true;
          var vid = this.$refs.videoRef;
          if (vid != undefined) {
            vid.load();
          }
        } else {
          this.form.bannerTranslationList[index].coverImgType = 1;
          this.form.bannerTranslationList[index].isVideo = false;
        }
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage(img, item, index) {
      this.form.bannerTranslationList[index].imageUrl = null;
      this.form.bannerTranslationList[index].imageBase64 = null;
      this.form.bannerTranslationList[index].fileNameComputer = "";
      this.form.bannerTranslationList[index].showPreview = null;

      if (this.form.bannerTranslationList[index].coverImgType == 2) {
        var vid = this.$refs.videoRef;
        vid.load();
        this.form.bannerTranslationList[index].coverImgType = 1;
      }
    },
    onMobileImageChange(img, item, index) {
      this.form.bannerTranslationList[index].isLoadingMobileImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.mobileImages = reader.result;
        let mobileImages = await this.saveImagetoDb(reader.result);
        this.form.bannerTranslationList[index].isLoadingMobileImage = false;
        this.isDisable = false;

        this.form.bannerTranslationList[index].mobileShowPreview = mobileImages;
        this.form.bannerTranslationList[index].mobileImageUrl = mobileImages;
        this.form.bannerTranslationList[index].fileNameMobile = mobileImages;

        if (img.type == "video/mp4") {
          this.form.bannerTranslationList[index].mobileCoverImgType = 2;
          this.form.bannerTranslationList[index].isMobileVideo = true;
          var vid = this.$refs.videoMobileRef;
          if (vid != undefined) {
            vid.load();
          }
        } else {
          this.form.bannerTranslationList[index].mobileCoverImgType = 1;
          this.form.bannerTranslationList[index].isMobileVideo = false;
        }
      };
    },
    deleteMobileImage(img, item, index) {
      this.form.bannerTranslationList[index].mobileImageUrl = null;
      this.form.bannerTranslationList[index].mobileImageBase64 = null;
      this.form.bannerTranslationList[index].fileNameMobile = "";
      this.form.bannerTranslationList[index].mobileShowPreview = null;

      if (this.form.bannerTranslationList[index].mobileCoverImgType == 2) {
        var vid = this.$refs.videoMobileRef;
        vid.load();
        this.form.bannerTranslationList[index].mobileCoverImgType = 1;
      }
    },
    checkForm: async function (flag) {
      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      }
      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidateTranslationList();
        return;
      }
      this.modalAlertShow = false;
      this.flag = flag;
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/BannerPopup/save`,
        null,
        this.$headers,
        this.form
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.existId = data.detail;
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        if (this.flag == 1) {
          setTimeout(() => {
            this.$router.push({
              path: `/banner-popup`,
            });
          }, 3000);
        } else {
          setTimeout(() => {
            this.$refs.modalAlert.hide();
          }, 3000);
          if (this.id > 0) {
            this.getDatas();
          } else {
            this.form.banner.id = this.existId;
            this.id = this.existId;
            this.isEdit = true;
            this.$router.push({
              path: `/banner-popup/details/${this.existId}`,
            });
          }
        }
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.banner.mainLanguageId = this.languageActive;
          let data = this.form.bannerTranslationList.filter(
            (val) => val.languageId == this.form.banner.mainLanguageId
          );

          if (this.id == 0) {
            if (data.length) {
              data = data[0];
              for (
                let index = 0;
                index < this.form.bannerTranslationList.length;
                index++
              ) {
                this.form.bannerTranslationList[index].altTag = data.altTag;
                this.form.bannerTranslationList[index].imageUrl = data.imageUrl;
                this.form.bannerTranslationList[index].showPreview =
                  data.imageUrl;
                this.form.bannerTranslationList[index].fileNameComputer =
                  data.imageUrl;
                this.form.bannerTranslationList[index].mobileImageUrl =
                  data.mobileImageUrl;
                this.form.bannerTranslationList[index].mobileShowPreview =
                  data.mobileImageUrl;
                this.form.bannerTranslationList[index].fileNameMobile =
                  data.mobileImageUrl;
              }
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.bannerTranslationList.filter(
            (val) => val.languageId != this.form.banner.mainLanguageId
          );
          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              // data.altTag = "";
              // data.showPreview = "";
              // data.fileNameComputer = "";
              // data.mobileShowPreview = "";
              // data.fileNameMobile = "";
            }
          }
        }
      });
    },
    checkValidateTranslationList: async function () {
      if (!this.form.isSameLanguage) {
        let isError = false;
        await this.languageList.forEach((element, index) => {
          if (!isError) {
            if (this.$v.form.bannerTranslationList.$each.$iter[index].$error) {
              console.log(
                this.$v.form.bannerTranslationList.$each.$iter[index]
              );

              this.languageActive =
                this.$v.form.bannerTranslationList.$model[index].languageId;

              this.imageLogoLang = this.languageList[index].imageUrl;

              isError = true;
            }
          }
        });
      }
    },
    btnDelete: async function () {
      this.$refs.isModalAlertConfirm.hide();

      let resData = await this.$callApi(
        "delete",
        `${this.$baseUrl}/api/BannerPopup/remove/${this.id}`,
        null,
        this.$headers,
        null
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/banner-popup`,
          });
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    openModalDelete(name) {
      this.modalMessage = "คุณต้องการลบ " + name + " ใช่หรือไม่?";
      this.$refs.isModalAlertConfirm.show();
    },
  },
};
</script>

<style scoped>
.panel {
  font-size: 17px;
  color: #16274a;
}
::v-deep .preview-box {
  background-size: contain;
}
</style>
